<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Punkty zdobyte</b> grupowane po uczestniku i grupie produktowej
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          title="Lista rozpoczętych sesji"
          v-if="state === 'loaded'"
        >
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                :items="gainedPointsPerGroup.items"
                :fields="gainedPointsPerGroup.fields"
                :filter="filter"
              >
              </b-table>
            </div>
          </template>
        </KTCodePreview>
        <div class="text-center" v-else>
          ta operacja może potrwać kilka minut ...
          <div class="d-flex justify-content-center mb-3">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import KTCodePreview from "@/view/content/CodePreview";

export default {
  name: "GainedPointsPerGroup",
  data() {
    return {
      state: "loading",
      gainedPointsPerGroup: {
        fields: [
          {
            key: "acronym",
            label: "Kontrahent",
            sortable: true,
          },
          {
            key: "skuDefaultGroup",
            label: "Grupa Domyślna",
            sortable: true,
          },
          {
            key: "point",
            label: "Punkty",
            sortable: true,
          },
        ],
        items: [],
      },
      filter: null,
    };
  },
  components: { KTCodePreview },
  methods: {
    getDefaultGroupPoints() {
      ApiService.get("/users/default/group/points").then((response) => {
        this.gainedPointsPerGroup.items = response.data;
        this.state = "loaded";
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Administrator" },
      { title: "Historia sesji" },
      { title: "" },
    ]);
    this.getDefaultGroupPoints();
  },
};
</script>
